import { Workbook } from "exceljs";
import { Child } from "../../types/Child";
import { AppUser } from "../../types/AppUsers";
import { Admin } from "../../types/Admin";
import { TeamMember } from "../../types/TeamMember";
import { Group } from "../../types/Group";

const UNKNOWN = "unbekannt";
const HEADERS = {
  v1: [
    "Vorname",
    "Nachname",
    "Geschlecht",
    "Geburtstag",
    "Straße",
    "PLZ",
    "Ort",
    "Nationalität",
    "Muttersprache",
    "Spricht deutsch",
    "Betreuungsform",
    "Betreuungsstunden / Woche",
    "Geschwister unter 18",
    "Eintrittsdatum",
    "Voraussichtliches Austrittsdatum",
    "Gruppe",
    "Erziehungsberechtigter A: Vorname",
    "Erziehungsberechtigter A: Nachname",
    "Erziehungsberechtigter A: Rolle / Verhältnis",
    "Erziehungsberechtigter A: Telefon",
    "Erziehungsberechtigter A: E-Mail-Adresse",
    "Erziehungsberechtigter A: Nationalität",
    "Erziehungsberechtigter A: Muttersprache",
    "Erziehungsberechtigter A: Spricht deutsch",
    "Erziehungsberechtigter B: Vorname",
    "Erziehungsberechtigter B: Nachname",
    "Erziehungsberechtigter B: Rolle / Verhältnis",
    "Erziehungsberechtigter B: Telefon",
    "Erziehungsberechtigter B: E-Mail-Adresse",
    "Erziehungsberechtigter B: Nationalität",
    "Erziehungsberechtigter B: Muttersprache",
    "Erziehungsberechtigter B: Spricht deutsch",
  ],
  v2: [
    "Vorname",
    "Nachname",
    "Geschlecht",
    "Geburtstag",
    "Straße",
    "PLZ",
    "Ort",
    "Nationalität",
    "Muttersprache",
    "Spricht deutsch",
    "Betreuungsform",
    "Betreuungsstunden / Woche",
    "Geschwister unter 18",
    "Eintrittsdatum",
    "Voraussichtliches Austrittsdatum",
    "Gruppe",
    "Erziehungsberechtigte:r 1: Vorname",
    "Erziehungsberechtigte:r 1: Nachname",
    "Erziehungsberechtigte:r 1: Rolle / Verhältnis",
    "Erziehungsberechtigte:r 1: Telefon",
    "Erziehungsberechtigte:r 1: E-Mail-Adresse",
    "Erziehungsberechtigte:r 1: Nationalität",
    "Erziehungsberechtigte:r 1: Muttersprache",
    "Erziehungsberechtigte:r 1: Spricht deutsch",
    "Erziehungsberechtigte:r 2: Vorname",
    "Erziehungsberechtigte:r 2: Nachname",
    "Erziehungsberechtigte:r 2: Rolle / Verhältnis",
    "Erziehungsberechtigte:r 2: Telefon",
    "Erziehungsberechtigte:r 2: E-Mail-Adresse",
    "Erziehungsberechtigte:r 2: Nationalität",
    "Erziehungsberechtigte:r 2: Muttersprache",
    "Erziehungsberechtigte:r 2: Spricht deutsch",
  ],
};

const mapBirthday = (date: string) => date || "2000-01-01";

const createImportWorkbook = (
  children: Child[],
  guardians: AppUser[],
  admins: Admin[],
  teamMembers: TeamMember[],
  groups: Group[],
  version: keyof typeof HEADERS
) => {
  const workbook = new Workbook();
  const sheet = workbook.addWorksheet("Import");
  const headers = HEADERS[version];
  sheet.addRow(headers);
  children.forEach((child) => {
    const row = [
      child.first_name,
      child.last_name,
      "divers",
      mapBirthday(child.birthday),
      UNKNOWN,
      UNKNOWN,
      UNKNOWN,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      groups.find(
        (group) => child.groups.length > 0 && group.id === child.groups[0].id
      )?.name || "",
    ];
    child.app_users
      .map(({ id }) => guardians.find((guardian) => guardian.id === id))
      .filter((user): user is AppUser => !!user)
      .forEach((user, index) => {
        if (index > 1) {
          return;
        }
        row.push(
          user.first_name,
          user.last_name,
          "Sonstige Person",
          UNKNOWN,
          user.email || UNKNOWN,
          "",
          "",
          ""
        );
      });
    sheet.addRow(row);
  });
  for (let i = 65; i <= 71; i += 1) {
    sheet.getCell(`${String.fromCharCode(i)}1`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "6ae5e5" },
    };
    sheet.getCell(`${String.fromCharCode(i)}1`).border = {
      top: { style: "thin", color: { argb: "999999" } },
      left: { style: "thin", color: { argb: "999999" } },
      bottom: { style: "thin", color: { argb: "999999" } },
      right: { style: "thin", color: { argb: "999999" } },
    };
  }
  sheet.columns = headers.map((header, index) => ({
    header,
    key: header,
    width: header.length + 4,
  }));
  return workbook;
};

export default createImportWorkbook;
