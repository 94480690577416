import React, { useState } from "react";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import Login from "./App/Login";
import Welcome from "./App/Welcome";
import FetchDataCard from "./App/FetchDataCard";
import { Child } from "./types/Child";
import { Group } from "./types/Group";
import { AppUser } from "./types/AppUsers";
import { Admin } from "./types/Admin";
import { TeamMember } from "./types/TeamMember";
import ShowDataDialog from "./App/ShowDataDialog";
import ExportExcelLink from "./App/ExportExcelLink";
import { Me } from "./types/Me";
import { AuthData } from "./types/AuthData";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#32cbcb",
    },
    secondary: {
      main: "#ff8158",
    },
    background: {
      default: "#f8ebdb",
    },
  },
  typography: {
    fontFamily: "Lato, Arial",
    h1: {
      fontSize: "4rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  },
  shape: {
    borderRadius: 12,
  },
});

const App = () => {
  const [loginData, setLoginData] = useState<{ me: Me; auth: AuthData }>();
  const [children, setChildren] = useState<Child[]>([]);
  const [guardians, setGuardians] = useState<AppUser[]>([]);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [showData, setShowData] = useState<string>();

  const showDataHandler = (data: string) => () => {
    setShowData((current) => (current === data ? undefined : data));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ backgroundColor: "#f8ebdb" }}>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4em",
            paddingBottom: "4em",
          }}
        >
          <Typography variant="h1" sx={{ paddingTop: "16px" }}>
            CARE-Umzugshelfer
          </Typography>
          <Typography variant="h5" sx={{ paddingBottom: "16px" }}>
            Wir helfen gerne bei Ihrem Umzug zu CARE
          </Typography>

          {loginData ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Welcome data={loginData.me} />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Kinder"
                    resourceName="children"
                    auth={loginData.auth}
                    onDataFetched={setChildren}
                    onClick={showDataHandler("Kinder")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Nutzer*innen"
                    resourceName="app-users"
                    auth={loginData.auth}
                    onDataFetched={setGuardians}
                    onClick={showDataHandler("Nutzer*innen")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Admins"
                    resourceName="admin-users"
                    auth={loginData.auth}
                    onDataFetched={setAdmins}
                    onClick={showDataHandler("Admins")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Teammitglieder"
                    resourceName="team-members"
                    auth={loginData.auth}
                    onDataFetched={setTeamMembers}
                    onClick={showDataHandler("Teammitglieder")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Gruppen"
                    resourceName="groups"
                    auth={loginData.auth}
                    onDataFetched={setGroups}
                    onClick={showDataHandler("Gruppen")}
                  />
                </Grid>
              </Grid>
              <ShowDataDialog
                showData={showData}
                onClose={() => setShowData(undefined)}
                children={children}
                guardians={guardians}
                admins={admins}
                teamMembers={teamMembers}
                groups={groups}
              />
            </>
          ) : (
            <Card>
              <CardHeader
                title="Stayinformed Login"
                subheader="Loggen Sie sich hier mit ihrem Stayinformed Zugang ein."
              />
              <CardContent>
                <Login onSuccessfulLogin={setLoginData} />
              </CardContent>
            </Card>
          )}
          <ExportExcelLink
            admins={admins}
            children={children}
            groups={groups}
            guardians={guardians}
            teamMembers={teamMembers}
          />
          <Card>
            <CardHeader
              title="Achtung"
              subheader="Einige Informationen werden nicht in StayInformed
              gepflegt, sie können daher auch nicht exportiert werden.
              Sie können diese Informationen nach dem Export in der Excel
              Tabelle oder nach dem Import in CARE anpassen.
              "
            />
            <CardContent>
              <List sx={{ listStyleType: "disc", marginLeft: "4em" }}>
                <ListItem sx={{ display: "list-item" }}>
                  Folgende Felder sind in CARE Pflichtfelder und werden auf
                  „unbekannt“ gesetzt:
                </ListItem>
                <List sx={{ listStyleType: "disc", marginLeft: "4em" }}>
                  <ListItem sx={{ display: "list-item" }}>Straße</ListItem>
                  <ListItem sx={{ display: "list-item" }}>PLZ</ListItem>
                  <ListItem sx={{ display: "list-item" }}>Ort</ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Telefonnummer
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    E-Mail-Addresse (wenn nicht bekannt)
                  </ListItem>
                </List>
                <ListItem sx={{ display: "list-item" }}>
                  Das Geschlecht des Kindes wird auf „divers“ gesetzt.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Der Geburtstag des Kindes wird, wenn nicht vorhanden, auf
                  „2000-01-01“ gesetzt.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Die Beziehung der App User zum Kind wird auf „Sonstige Person“
                  gesetzt.
                </ListItem>
              </List>
              <Typography></Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Datenschutz" />
            <CardContent>
              <Typography variant="body1">
                Diese Seite greift mit Hilfe Ihres StayInformed Logins auf Ihre
                StayInformed Instanz zu und lädt Informationen über Kinder, App
                Nutzer:innen, Admins, Teammitglieder und Gruppen. Daraus
                erstellt sie ein Excel Dokument, dass Sie in CARE für den Import
                von Kindern nutzen können.
              </Typography>
              <Typography variant="body1">
                Informationen aus Ihrer StayInformed Instanz werden weder auf
                unseren Servern, noch sonst irgendwo zwischengespeichert.
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default App;
