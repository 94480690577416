import { Container, TextField, Typography } from "@mui/material";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import login, { login2fa } from "../api/login";
import { LoadingButton } from "@mui/lab";
import fetchMe from "../api/me";
import { AuthData } from "../types/AuthData";
import { Me } from "../types/Me";
import {
  TwoFactorChallengeResponse,
  isLoginResponse,
} from "../types/LoginResponse";

const readAuth = () => {
  try {
    return JSON.parse(localStorage.getItem("auth") || "") as AuthData;
  } catch (_) {
    return undefined;
  }
};

const writeAuth = (auth: AuthData) => {
  localStorage.setItem("auth", JSON.stringify(auth));
  document.cookie = JSON.stringify(auth);
};

const Login = ({
  onSuccessfulLogin,
}: {
  onSuccessfulLogin: (data: { me: Me; auth: AuthData }) => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [twoFactorChallenge, setTwoFactorChallenge] =
    useState<TwoFactorChallengeResponse>();
  const [auth, setAuth] = useState(readAuth());

  const onChangeEmail: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.target.value);
  };

  const onChangePassword: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value);
  };

  const onChangeCode: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCode(event.target.value);
  };

  const onLogin = () => {
    setLoading(true);
    setError("");
    if (twoFactorChallenge) {
      login2fa(twoFactorChallenge, code)
        .then((result) => setAuth(result.auth))
        .catch((error) => {
          const message = error.response.data.message;
          setError(message);
        });
    } else {
      login(email, password)
        .then((result) => {
          if (isLoginResponse(result)) {
            setAuth(result.auth);
          } else {
            setTwoFactorChallenge(result);
          }
        })
        .catch((error) => {
          if (!error.response) {
            throw error;
          }
          const message = error.response.data.message;
          setError(
            message === "Invalid username or password."
              ? "Falscher Username oder falsches Passwort."
              : message
          );
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!auth) return () => null;
    const controller = new AbortController();
    setLoading(true);
    setError("");
    fetchMe(auth, controller)
      .then((me) => {
        writeAuth(auth);
        onSuccessfulLogin({ me, auth });
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        // @ts-ignore
        if (error.response?.data?.message === "Expired token") {
          setError("");
          setLoading(false);
          return;
        }
        if (error.name !== "CanceledError") {
          setError(error.name);
        }
      });

    return () => {
      controller.abort();
    };
  }, [auth, onSuccessfulLogin]);

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
      maxWidth="sm"
      className="form"
      onSubmit={onLogin}
    >
      {" "}
      {twoFactorChallenge ? (
        <>
          <Typography key="hint">{`Geben Sie bitte den 6-Stelligen Verifizierungs-Code ein ${
            twoFactorChallenge.meta.method === "app"
              ? "der von Ihrer Authenticator-App generiert wurde"
              : "den Sie per E-Mail empfangen haben"
          }`}</Typography>
          <TextField
            key={"code"}
            variant="outlined"
            label="Bestätigungscode"
            onChange={onChangeCode}
            error={!!error}
            helperText={error}
          />
        </>
      ) : (
        <>
          <TextField
            key="email"
            variant="outlined"
            label="E-Mail"
            onChange={onChangeEmail}
            error={!!error}
            helperText={error}
          />
          <TextField
            key="password"
            variant="outlined"
            label="Passwort"
            type="password"
            onChange={onChangePassword}
            error={!!error}
            helperText={error}
          />
        </>
      )}
      <LoadingButton variant="outlined" onClick={onLogin} loading={loading}>
        Einloggen
      </LoadingButton>
    </Container>
  );
};

export default Login;
